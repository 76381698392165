import Mock from '../mock';

const database = {
  information: {
    name: 'Gal Agai',
    aboutContent:
      'I am a Full-Stack web developer. I can provide clean code and pixel perfect design. I also make website, web apps & mobile apps(React Native).',
    phone: '',
    nationality: 'Portuguese, Israeli',
    language: 'English, Hebrew',
    email: '',
    address: '43 Kam Street, Tel-Aviv, Israel',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/gal.agay/',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/gal-agai-a5a3826',
      dribbble: '',
      github: 'https://github.com/gaagai',
    },
    brandImage: '/images/brand-image.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/galagai.pdf',
  },

  skills: [
    {
      title: 'HTML5',
      value: 95,
    },
    {
      title: 'CSS3',
      value: 97,
    },
    {
      title: 'Javascript',
      value: 85,
    },
    {
      title: 'jQuery',
      value: 95,
    },
    {
      title: 'ReactJS',
      value: 95,
    },
    {
      title: 'Photoshop',
      value: 75,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: 'Netitation web app',
      subtitle: 'Full-Stack - MERN.',
      imageUrl: '/images/netitation550.png',
      largeImageUrl: ['/images/netitation1920.png'],
      url: 'https://meditation-inky.vercel.app/',
    },
    {
      id: 2,
      title: 'Etlv App',
      subtitle: 'A React Native app.',
      imageUrl: '/images/etlv550.png',
      largeImageUrl: ['/images/etlv1920.png'],
      url: 'https://play.google.com/store/apps/details?id=dev.etlv.tel.aviv.app&hl=en&gl=US',
    },
    {
      id: 3,
      title: 'Mapenu',
      subtitle: 'Full-Stack - MERN.',
      imageUrl: '/images/mapenu550.png',
      largeImageUrl: ['/images/mapenu.png'],
      url: 'https://mapenu.com/',
    },
    {
      id: 4,
      title: 'City Weather App',
      subtitle: 'React web app.',
      imageUrl: '/images/weather550.png',
      largeImageUrl: ['/images/weather1920.png'],
      url: 'https://weather-gama33.vercel.app/',
    },
    {
      id: 5,
      title: 'Locations',
      subtitle: 'React UI',
      imageUrl: '/images/local550.png',
      largeImageUrl: ['/images/local1920.png'],
      url: 'https://testreact34.herokuapp.com/',
    },
    {
      id: 6,
      title: 'e-commerce',
      subtitle: 'Next.js React, MogoDB',
      imageUrl: '/images/shop550.png',
      largeImageUrl: ['/images/shop1920.png'],
      url: 'https://react-next-store.herokuapp.com/',
    },
    {
      id: 7,
      title: 'React Trivia',
      subtitle: 'React Web App.',
      imageUrl: '/images/brain550.png',
      largeImageUrl: ['/images/brain1900.png'],
      url: 'https://quiet-river-81923.herokuapp.com/',
    },
    {
      id: 8,
      title: 'Sticker',
      subtitle: 'Clip sticker mockup design.',
      imageUrl: '/images/grande.png',
      largeImageUrl: ['/images/grande1920.png'],
      url: 'https://quirky-engelbart-34bcdd.netlify.app/',
    },
    {
      id: 9,
      title: 'Lights Out Game',
      subtitle: 'A React.js Game.',
      imageUrl: '/images/light.png',
      largeImageUrl: ['/images/light550.png'],
      url: 'https://eager-elion-cd2927.netlify.app/',
    },
    {
      id: 10,
      title: 'React Cheat Sheet',
      subtitle: 'A simple website in React.',
      imageUrl: '/images/react.png',
      largeImageUrl: ['/images/react1920.png'],
      url: 'https://afternoon-retreat-79091.herokuapp.com/',
    },
    {
      id: 11,
      title: 'To Do App',
      subtitle: 'React 2Do app.',
      imageUrl: '/images/2do.png',
      largeImageUrl: ['/images/2do1920.png'],
      url: 'https://5d4daae7acfdaadad82d661d--thirsty-clarke-940264.netlify.app/',
    },
    {
      id: 12,
      title: 'Youtube search',
      subtitle: 'React webapp.',
      imageUrl: '/images/you.png',
      largeImageUrl: ['/images/you1920.png'],
      url: 'https://youtapp.netlify.app/',
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: '2018 - Present',
        position: 'Frontend Web Developer',
        company: 'Abc Company',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
      {
        id: 2,
        year: '2016 - 2018',
        position: 'Frontend Web Developer',
        company: 'CBA Company',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
      {
        id: 3,
        year: '2014 - 1016',
        position: 'UI/UX Designer',
        company: 'Example Company',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: '2018 - 2019',
        graduation: 'Master of Science',
        university: 'Abc University',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
      {
        id: 2,
        year: '2016 - 2018',
        graduation: 'Bachelor of Science',
        university: 'Abc University',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
      {
        id: 3,
        year: '2015 - 2016',
        graduation: 'Higher Schoold Graduation',
        university: 'Abc College',
        details:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.',
      },
    ],
  },
  contactInfo: {
    phoneNumbers: ['+972-58-787-7717'],
    emailAddress: ['agaigal@gmail.com'],
    address: '43 Kam Street, Tel-Aviv, Israel',
  },
};

Mock.onGet('/api/information').reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet('/api/services').reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet('/api/reviews').reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet('/api/skills').reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet('/api/portfolios').reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet('/api/experience').reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet('/api/blog').reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet('/api/contactinfo').reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
